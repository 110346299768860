import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {path: '', loadChildren: () => import('./account-start/account-start.module').then(m=> m.AccountStartModule)},
  {path: 'customerregstration', loadChildren: () => import('./customer-registration/customer-registration.module').then(m=> m.CustomerRegistrationModule)},
  {path: 'userActivation', loadChildren: () => import('.//user-activation/user-activation.module').then(m=> m.UserActivationModule)},
  {path: 'accountStart', loadChildren: () => import('./account-start/account-start.module').then(m=> m.AccountStartModule)},
  {path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
  {path: 'keyuser', loadChildren: () => import('./key-user/key-user.module').then(m => m.KeyUserModule)},
  {path: 'applicationuser', loadChildren: () => import('./application-user/application-user.module').then(m => m.ApplicationUserModule)},
  {path: 'common', loadChildren: () => import('bcSharedComponents').then(m => m.BcCommonModule)},
  {path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)},
  {path: 'haendlersuche', loadChildren: () => import('./partner-map/partner-map.module').then(m => m.PartnerMapModule)},
  {path: 'partnerprogramm', loadChildren: () => import('./partnerprogramm/partnerprogramm.module').then(m => m.PartnerprogrammModule)},
  {path: 'dataprotection', loadChildren: () => import('../../../bc-shared-components/src/lib/privacy-notice/privacy-notice.module').then(m => m.PrivacyNoticeModule)},
  {path: 'rechnungen', loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule) },
  {path: 'auftraege', loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule) },
  {path: 'aktionen', loadChildren: () => import('./bonus-actions/bonus-actions.module').then(m => m.BonusActionsModule) },
  {path: 'banking', loadChildren: () => import('./banking/banking.module').then(m => m.BankingModule) },
  {path: 'preislisten', loadChildren: () => import('./preislisten/preislisten.module').then(m => m.PreislistenModule) },
  {path: '558b707e735f4e0cb348ad5ac5f07067', loadChildren: () => import('./bonus-actions/identity-verification/identity-verification.module').then(m => m.IdentityVerificationModule)}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }