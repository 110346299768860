import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BcFooterModule, BcSnackbarModule, TenantInterceptor } from 'bcSharedComponents'
import { BcHeaderModule } from 'bcSharedComponents'
import { BcAuthCoreModule } from 'bcSharedComponents';
import { BcWwsWebApiModule, Configuration } from 'bcWwsWebApi';
import { AuthConfig, OAuthModuleConfig} from 'angular-oauth2-oidc';
import {environment} from "../environments/environment";
import { BROWSERS, DeviceDetectorService } from 'ngx-device-detector';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BcAuthCoreModule.forRoot(() => {
      const deviceDetectorService = inject(DeviceDetectorService);
      const supportsSilentRefresh = !(deviceDetectorService.isTablet() || deviceDetectorService.isMobile() || deviceDetectorService.browser === BROWSERS.SAFARI);
      const authConfig: AuthConfig = {
          postLogoutRedirectUri: window.location.origin,
          issuer: environment.keycloakUrl + "/realms/" + environment.realm,
          clientId: environment.clientId,
          responseType: 'code',
          redirectUri: window.location.origin + '/index.html',
          silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
          scope: 'openid profile email roles', // Ask offline_access to support refresh token refreshes
          useSilentRefresh: supportsSilentRefresh, // Needed for Code Flow to suggest using iframe-based refreshes
          silentRefreshTimeout: 5000, // For faster testing
          timeoutFactor: 0.75, // For faster testing
          sessionChecksEnabled: true,
          sessionCheckIntervall: 5000,
          showDebugInformation: !environment.production, // Also requires enabling "Verbose" level in devtools
          clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
          nonceStateSeparator: 'semicolon' // Real semicolon gets mangled by IdentityServer's URI encoding
      };
      return authConfig;
  },
  () => {
      const authModuleConfig: OAuthModuleConfig = {
          resourceServer: {
              allowedUrls: [environment.restApiUrl],
              sendAccessToken: true,
          }
      };
      return authModuleConfig;
  },
  () => {
    const tenantInterceptor: TenantInterceptor =  new TenantInterceptor()
    tenantInterceptor.interceptRoutes = [environment.restApiUrl];
    return tenantInterceptor;
  }
),
    BcWwsWebApiModule.forRoot(() => {
      const config = new Configuration();
      config.basePath = environment.restApiUrl;
      return config;
  }),
    BcFooterModule,
    BcHeaderModule,
    BcSnackbarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
