import { Component } from '@angular/core';
import { Destroyer, IApplicationItem, IMenuItem, MenuItemVisibility, exsistingRoles } from 'bcSharedComponents';
import { StatisticsService } from 'bcWwsWebApi';
import { Observable, from, map, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';  // Import the environment configuration

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent extends Destroyer {
  //partnerprogramm
  title = 'bluechip Partnercenter';
  menuItems: IMenuItem[] = [
    {displayName: 'Startseite', routerLink: ['accountStart'], requiredRoles: [], visibility: MenuItemVisibility.BeforeLogin}, 
    {displayName: 'Dashboard', routerLink: ['dashboard'], requiredRoles: [], visibility: MenuItemVisibility.AfterLogin}, 
    {displayName: 'Meine Firma', routerLink: ['applicationuser'], requiredRoles: [], visibility: MenuItemVisibility.AfterLogin, 
    subItems: [
      {displayName: 'Mitarbeiter', routerLink: ['applicationuser'], requiredRoles: [exsistingRoles.KeyUser], visibility: MenuItemVisibility.AfterLogin},
      {displayName: 'Offene Aufträge', routerLink: ['auftraege'], requiredRoles: [], visibility: MenuItemVisibility.AfterLogin},
      {displayName: 'Rechnungen', routerLink: ['rechnungen'], requiredRoles: [], visibility: MenuItemVisibility.AfterLogin},
      {displayName: 'Partnerprogramm', routerLink: ['partnerprogramm'], requiredRoles: [exsistingRoles.KeyUser], visibility: MenuItemVisibility.AfterLogin},
      {displayName: 'Preislisten', routerLink: ['preislisten'], requiredRoles: [], visibility: MenuItemVisibility.AfterLogin}
    ]}, 

    {displayName: 'Administratoren', routerLink: ['keyuser'], requiredRoles: [exsistingRoles.KeyUserManage], visibility: MenuItemVisibility.AfterLogin},
    {displayName: 'Userlog', routerLink: ['keyuser','userlog'], requiredRoles: [exsistingRoles.KeyUserManage], visibility: MenuItemVisibility.AfterLogin},
    {displayName: 'FAQ', routerLink: ['faq'], requiredRoles: [], visibility: MenuItemVisibility.Always},
    // {displayName: 'Datenschutzhinweise', routerLink: ['dataprotection'], requiredRoles: [], visibility: MenuItemVisibility.Always},
  ];
  applicationItems$: Observable<IApplicationItem[]> = from([]);
  
  constructor(private statisticsService: StatisticsService){
    super();
  }

  ngOnInit(): void {
    this.applicationItems$ = this.statisticsService
                              .apiStatisticsGetApplicationsGet()
                              .pipe(takeUntil(this.destroy$), map(value => value.map( x => ({ displayName: x.displayString, url: x.value, icon: x.icon } as IApplicationItem))));
  }
}
